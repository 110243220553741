  import React, { useState } from 'react'
  import PropTypes from 'prop-types';
  import { css } from '@emotion/react';
  import { breakpoints } from '~styles/global'

  const Filter = ({ className, options, onChange, currentOption }) => {

  const {mobile} = breakpoints  
  const [active, setActive] = useState(currentOption ?? null)

  const handleClick = clickedOption => {
    setActive(clickedOption)
    if(onChange){
      onChange(clickedOption)
    }
    return
  }

  return (
    <div
      className={className}
      css={css`
      `}
    >
      {options.map(option => (
        <button
          className={`h6`}
          key={option.id} 
          onClick={() => handleClick(option?.title)}
          css={css`
            color:${ active === null ? `inherit` : active === option.title ? `inherit` : `var(--grey)`};
            transition: 0.2s color;
            text-align: left;
            display: block;
            margin-bottom: 1px;
            &:hover{ 
              color: inherit;
            }
            ${mobile}{
              margin-bottom: 10px;
            }
        `}>
          {option.title}
        </button>
      ))}
    </div>
  )
  }

  Filter.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    title: PropTypes.string,
    id: PropTypes.string,
  })),
  currentOption: PropTypes.string,
  onChange: PropTypes.func,
  }

  Filter.defaultProps = {
    currentOption: `option1`,
    options: [
      {
        key: `1`,
        title: `All`,
        id: `option1`,
      },
      {
        key: `2`,
        title: `News`,
        id: `option2`,
      },
      {
        key: `3`,
        title: `Brand Profile`,
        id: `option3`,
      },
      {
        key: `4`,
        title: `Events`,
        id: `option4`,
      },
      {
        key: `5`,
        title: `Category`,
        id: `option5`,
      },
    ],
    onChange: () => null,
  }

  export default Filter

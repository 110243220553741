import React from 'react'
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { Circle, Curve } from '../../Svg';

const Loadingblock = ({ className, totalNumber, numberLoaded }) => {
  return (
    <div
      className={className}
      css={css`
        display: grid;
        justify-content: center;
      `}
    >
      <div css={css`
        position: relative;
        width: 15px;
        height: 15px;
        animation: spin 1s infinite;
        justify-self: center;
      `}>
        <Circle  css={css`
          width: 15px; 
          height: 15px; 
          color: var(--grey);
          position: absolute;
        `}/>  
        <Curve css={css`
          width: 9px; 
          height: 9px;
          position: absolute;
          top: 0;
          right: 0;
        `}/>
      </div>
      <h6 css={css`
        margin-top: 19px;
      `}>Loading {numberLoaded} of {totalNumber}</h6>
    </div>
  )
}

Loadingblock.propTypes = {
  totalNumber: PropTypes.number,
  numberLoaded: PropTypes.number,
}

Loadingblock.defaultProps = {
  totalNumber: 130,
  numberLoaded: 40,
}

export default Loadingblock
